import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { trim } from 'lodash'
import { saveAs } from 'file-saver'
import pptxgen from 'pptxgenjs'
import tinycolor from 'tinycolor2'
import { toPng, toJpeg, toBlob, toSvg, getFontEmbedCSS } from 'html-to-image'
import { saveAsPng, saveAsJpeg } from 'save-html-as-image'
import { useSlidesStore } from '@/store'
import { PPTElementOutline, PPTElementShadow, PPTElementLink, Slide } from '@/types/slides'
import { getElementRange, getLineElementPath, getTableSubThemeColor } from '@/utils/element'
import { AST, toAST } from '@/utils/htmlParser'
import { SvgPoints, toPoints } from '@/utils/svgPathParser'
import { decrypt, encrypt } from '@/utils/crypto'
import { svg2Base64 } from '@/utils/svg2Base64'
import { message } from 'ant-design-vue'
import useAddSlidesOrElements from '@/hooks/useAddSlidesOrElements'
import html2canvas from 'html2canvas'
import { isSupportedImage } from 'html2canvas/dist/types/css/types/image'


interface ExportImageConfig {
  quality: number
  width?: number
  height?: number
  canvasWidth?: number
  canvasHeight?: number
  pixelRatio?: number
  backgroundColor?: string
  cacheBust?: boolean
  skipAutoScale?: boolean
  fontEmbedCSS?: string
}


export default () => {
  const slidesStore = useSlidesStore()
  const { slides, theme, viewportRatio } = storeToRefs(slidesStore)

  const { addSlidesFromData } = useAddSlidesOrElements()

  const exporting = ref(false)

  const tip = ref<string>('')

  const showImage = ref([])

  const uploadUrl = ref([])

  const lineNum = ref(0)

  // 上传进度统计
  const pressNum = ref(0)


  const getBase64 = (data) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([data], { type: 'image/png' })
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }


  // ios
  const iosImage = (domRef: HTMLElement, format: string, quality: number, ignoreWebfont = true) => { 
    return new Promise(resolve => {
      const toImage = format === 'png' ? toPng : toJpeg

      const max = domRef.querySelectorAll('.elements').length
      const minNum = 100 / max

      // 分别合并各图片
      domRef.querySelectorAll('.elements').forEach(element => {
        console.log(element)
        tip.value = '开始合并元素'

        // element.removeAttribute('style')

        console.log(element.getAttribute('data-scale'))
        // eslint-disable-next-line prefer-const
        let width: unknown = element.getAttribute('data-width')
        // eslint-disable-next-line prefer-const
        let height: unknown = element.getAttribute('data-height')


        html2canvas(element as HTMLElement, {
          useCORS: true,
          backgroundColor: '#f1f1f1',
        }).then((canvas) => {
          // const dataUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
          const dataUrl = canvas.toDataURL('image/png')

          console.log('ios:')
          console.log(dataUrl)
          // 下载
          tip.value = 'ios 生成中'
          // 单个图片上传
          slidesStore.updateImage(dataUrl, format).then((res) => {
            // 将上传成功的图片记录到变量
            (uploadUrl.value as Array<string>).push(res.data.url)
            pressNum.value += minNum
            lineNum.value += 1
            tip.value = '图片上传顺序：' + res.data.id

            showImage.value.push(dataUrl)
            if (lineNum.value === max) {
              resolve('long_time_value')
            }
          })
        })

      })
    })
  }

  // 安卓
  const androidImage = (domRef: HTMLElement, format: string, quality: number, ignoreWebfont = true) => {
    return new Promise(resolve => {
      const toImage = format === 'png' ? toPng : toJpeg

      const max = domRef.querySelectorAll('.elements').length
      const minNum = 100 / max

      // 分别合并各图片
      domRef.querySelectorAll('.elements').forEach(element => {
        console.log(element)
        tip.value = '开始合并元素'

        // 参数
        const config: ExportImageConfig = {
          quality,
          cacheBust: false,
          backgroundColor: '#f1f1f1',
          skipAutoScale: false,
          pixelRatio: 1,
          height: domRef.scrollHeight,
          canvasWidth: domRef.scrollWidth * 2,
          canvasHeight: domRef.scrollHeight * 2,
          // style: {backgroundColor: 'transparent'},
          // fontEmbedCSS: fontEmbedCss
        }
        toImage(element as HTMLElement, config).then(dataUrl => {
          // 下载
          tip.value = dataUrl
          // 单个图片上传
          slidesStore.updateImage(dataUrl, format).then((res) => {
            // 将上传成功的图片记录到变量
            (uploadUrl.value as Array<string>).push(res.data.url)
            pressNum.value += minNum
            lineNum.value += 1
            tip.value = '图片上传顺序：' + res.data.id

            showImage.value.push(dataUrl)
            if (lineNum.value === max) {
              resolve('long_time_value')
            }
          })
        }).catch(err => {
          console.error(err)
          tip.value = err.message || 'toImage 出错'
        })
      })
    })
  }



  // 导出图片
  const exportImageSingle = (domRef: HTMLElement, format: string, quality: number, ignoreWebfont = true) => {
    const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    exporting.value = true
    tip.value = '准备生成中'

    if (isiOS) {
      return iosImage(domRef, format, quality)
    }
    return androidImage(domRef, format, quality)
  }

  // 导出图片
  const exportImage = (domRef: HTMLElement, format: string, quality: number, ignoreWebfont = true) => {
    exporting.value = true
    const toImage = format === 'png' ? toPng : toJpeg

    const foreignObjectSpans = domRef.querySelectorAll('foreignObject [xmlns]')
    foreignObjectSpans.forEach(spanRef => spanRef.removeAttribute('xmlns'))

    setTimeout(() => {
      const config: ExportImageConfig = {
        quality,
        width: 1600,
      }

      if (ignoreWebfont) config.fontEmbedCSS = ''

      console.log(config)

      toBlob(domRef, config).then(dataUrl => {
        exporting.value = false
        saveAs(dataUrl, `pptist_slides.${format}`)
      }).catch(() => {
        exporting.value = false
        message.error('导出图片失败')
      })


    }, 200)
  }
  
  // 导出pptist文件（特有 .pptist 后缀文件）
  const exportSpecificFile = (_slides: Slide[]) => {
    const blob = new Blob([encrypt(JSON.stringify(_slides))], { type: '' })
    saveAs(blob, 'pptist_slides.pptist')
  }
  
  // 导入pptist文件
  const importSpecificFile = (files: FileList, cover = false) => {
    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      try {
        const slides = JSON.parse(decrypt(reader.result as string))
        if (cover) slidesStore.setSlides(slides)
        else addSlidesFromData(slides)
      }
      catch {
        message.error('无法正确读取 / 解析该文件')
      }
    })
    reader.readAsText(file)
  }
  
  // 导出JSON文件
  const exportJSON = () => {
    const blob = new Blob([JSON.stringify(slides.value)], { type: '' })
    saveAs(blob, 'pptist_slides.json')
  }

  // 格式化颜色值为 透明度 + HexString，供pptxgenjs使用
  const formatColor = (_color: string) => {
    const c = tinycolor(_color)
    const alpha = c.getAlpha()
    const color = alpha === 0 ? '#ffffff' : c.setAlpha(1).toHexString()
    return {
      alpha,
      color,
    }
  }

  type FormatColor = ReturnType<typeof formatColor>

  // 将HTML字符串格式化为pptxgenjs所需的格式
  // 核心思路：将HTML字符串按样式分片平铺，每个片段需要继承祖先元素的样式信息，遇到块级元素需要换行
  const formatHTML = (html: string) => {
    const ast = toAST(html)
    let bulletFlag = false
    let indent = 0

    const slices: pptxgen.TextProps[] = []
    const parse = (obj: AST[], baseStyleObj = {}) => {

      for (const item of obj) {
        const isBlockTag = 'tagName' in item && ['div', 'li', 'p'].includes(item.tagName)

        if (isBlockTag && slices.length) {
          const lastSlice = slices[slices.length - 1]
          if (!lastSlice.options) lastSlice.options = {}
          lastSlice.options.breakLine = true
        }

        const styleObj = { ...baseStyleObj }
        const styleAttr = 'attributes' in item ? item.attributes.find(attr => attr.key === 'style') : null
        if (styleAttr && styleAttr.value) {
          const styleArr = styleAttr.value.split(';')
          for (const styleItem of styleArr) {
            const [_key, _value] = styleItem.split(': ')
            const [key, value] = [trim(_key), trim(_value)]
            if (key && value) styleObj[key] = value
          }
        }

        if ('tagName' in item) {
          if (item.tagName === 'em') {
            styleObj['font-style'] = 'italic'
          }
          if (item.tagName === 'strong') {
            styleObj['font-weight'] = 'bold'
          }
          if (item.tagName === 'sup') {
            styleObj['vertical-align'] = 'super'
          }
          if (item.tagName === 'sub') {
            styleObj['vertical-align'] = 'sub'
          }
          if (item.tagName === 'a') {
            const attr = item.attributes.find(attr => attr.key === 'href')
            styleObj['href'] = attr?.value || ''
          }
          if (item.tagName === 'ul') {
            styleObj['list-type'] = 'ul'
          }
          if (item.tagName === 'ol') {
            styleObj['list-type'] = 'ol'
          }
          if (item.tagName === 'li') {
            bulletFlag = true
          }
          if (item.tagName === 'p') {
            if ('attributes' in item) {
              const dataIndentAttr = item.attributes.find(attr => attr.key === 'data-indent')
              if (dataIndentAttr && dataIndentAttr.value) indent = +dataIndentAttr.value
            }
          }
        }

        if ('tagName' in item && item.tagName === 'br') {
          slices.push({ text: '', options: { breakLine: true } })
        }
        else if ('content' in item) {
          const text = item.content.replace(/&nbsp;/g, ' ').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&').replace(/\n/g, '')
          const options: pptxgen.TextPropsOptions = {}

          if (styleObj['font-size']) {
            options.fontSize = parseInt(styleObj['font-size']) * 0.75
          }
          if (styleObj['color']) {
            options.color = formatColor(styleObj['color']).color
          }
          if (styleObj['background-color']) {
            options.highlight = formatColor(styleObj['background-color']).color
          }
          if (styleObj['text-decoration-line']) {
            if (styleObj['text-decoration-line'].indexOf('underline') !== -1) {
              options.underline = {
                color: options.color || '#000000',
                style: 'sng',
              }
            }
            if (styleObj['text-decoration-line'].indexOf('line-through') !== -1) {
              options.strike = 'sngStrike'
            }
          }
          if (styleObj['text-decoration']) {
            if (styleObj['text-decoration'].indexOf('underline') !== -1) {
              options.underline = {
                color: options.color || '#000000',
                style: 'sng',
              }
            }
            if (styleObj['text-decoration'].indexOf('line-through') !== -1) {
              options.strike = 'sngStrike'
            }
          }
          if (styleObj['vertical-align']) {
            if (styleObj['vertical-align'] === 'super') options.superscript = true
            if (styleObj['vertical-align'] === 'sub') options.subscript = true
          }
          if (styleObj['text-align']) options.align = styleObj['text-align']
          if (styleObj['font-weight']) options.bold = styleObj['font-weight'] === 'bold'
          if (styleObj['font-style']) options.italic = styleObj['font-style'] === 'italic'
          if (styleObj['font-family']) options.fontFace = styleObj['font-family']
          if (styleObj['href']) options.hyperlink = { url: styleObj['href'] }

          if (bulletFlag && styleObj['list-type'] === 'ol') {
            options.bullet = { type: 'number', indent: 20 * 0.75 }
            options.paraSpaceBefore = 0.1
            bulletFlag = false
          }
          if (bulletFlag && styleObj['list-type'] === 'ul') {
            options.bullet = { indent: 20 * 0.75 }
            options.paraSpaceBefore = 0.1
            bulletFlag = false
          }
          if (indent) {
            options.indentLevel = indent
            indent = 0
          }

          slices.push({ text, options })
        }
        else if ('children' in item) parse(item.children, styleObj)
      }
    }
    parse(ast)
    return slices
  }

  type Points = Array<
    | { x: number; y: number; moveTo?: boolean }
    | { x: number; y: number; curve: { type: 'arc'; hR: number; wR: number; stAng: number; swAng: number } }
    | { x: number; y: number; curve: { type: 'quadratic'; x1: number; y1: number } }
    | { x: number; y: number; curve: { type: 'cubic'; x1: number; y1: number; x2: number; y2: number } }
    | { close: true }
  >

  // 将SVG路径信息格式化为pptxgenjs所需要的格式
  const formatPoints = (points: SvgPoints, scale = { x: 1, y: 1 }): Points => {
    return points.map(point => {
      if (point.close !== undefined) {
        return { close: true }
      }
      else if (point.type === 'M') {
        return {
          x: point.x / 100 * scale.x,
          y: point.y / 100 * scale.y,
          moveTo: true,
        }
      }
      else if (point.curve) {
        if (point.curve.type === 'cubic') {
          return {
            x: point.x / 100 * scale.x,
            y: point.y / 100 * scale.y,
            curve: {
              type: 'cubic',
              x1: (point.curve.x1 as number) / 100 * scale.x,
              y1: (point.curve.y1 as number) / 100 * scale.y,
              x2: (point.curve.x2 as number) / 100 * scale.x,
              y2: (point.curve.y2 as number) / 100 * scale.y,
            },
          }
        }
        else if (point.curve.type === 'quadratic') {
          return {
            x: point.x / 100 * scale.x,
            y: point.y / 100 * scale.y,
            curve: {
              type: 'quadratic',
              x1: (point.curve.x1 as number) / 100 * scale.x,
              y1: (point.curve.y1 as number) / 100 * scale.y,
            },
          }
        }
      }
      return {
        x: point.x / 100 * scale.x,
        y: point.y / 100 * scale.y,
      }
    })
  }

  // 获取阴影配置
  const getShadowOption = (shadow: PPTElementShadow): pptxgen.ShadowProps => {
    const c = formatColor(shadow.color)
    const { h, v } = shadow

    let offset = 4
    let angle = 45

    if (h === 0 && v === 0) {
      offset = 4
      angle = 45
    }
    else if (h === 0) {
      if (v > 0) {
        offset = v
        angle = 90
      }
      else {
        offset = -v
        angle = 270
      }
    }
    else if (v === 0) {
      if (h > 0) {
        offset = h
        angle = 1
      }
      else {
        offset = -h
        angle = 180
      }
    }
    else if (h > 0 && v > 0) {
      offset = Math.max(h, v)
      angle = 45
    }
    else if (h > 0 && v < 0) {
      offset = Math.max(h, -v)
      angle = 315
    }
    else if (h < 0 && v > 0) {
      offset = Math.max(-h, v)
      angle = 135
    }
    else if (h < 0 && v < 0) {
      offset = Math.max(-h, -v)
      angle = 225
    }

    return {
      type: 'outer',
      color: c.color.replace('#', ''),
      opacity: c.alpha,
      blur: shadow.blur * 0.75,
      offset,
      angle,
    }
  }

  // 获取边框配置
  const getOutlineOption = (outline: PPTElementOutline): pptxgen.ShapeLineProps => {
    const c = formatColor(outline?.color || '#000000')
    return {
      color: c.color, 
      transparency: (1 - c.alpha) * 100,
      width: (outline.width || 1) * 0.75, 
      dashType: outline.style === 'solid' ? 'solid' : 'dash',
    }
  }

  // 获取超链接配置
  const getLinkOption = (link: PPTElementLink): pptxgen.HyperlinkProps | null => {
    const { type, target } = link
    if (type === 'web') return { url: target }
    if (type === 'slide') {
      const index = slides.value.findIndex(slide => slide.id === target)
      if (index !== -1) return { slide: index + 1 }
    }

    return null
  }


  return {
    tip,
    exporting,
    showImage,
    uploadUrl,
    pressNum,
    exportImageSingle,
    exportImage,
    exportJSON,
    importSpecificFile,
    exportSpecificFile
  }
}