export interface videoRef {
	// 其他冗余字段
	[propName: string]: any
	// 数字值，表示当前播放的时间，以秒计
	currentTime: number
}


export class cropFilter {
	// 结束按钮距离左侧距离
	endLeft: string | number = 0
	// 结束按钮初始位置
	endright: string | number = 0
	// 开始按钮距离左侧距离
  startLeft: string | number = 0

	// 毫秒/px(1px===的毫秒数)
	roal: string | number = 0
	// 开始时间
	startTime: string | number = 0
	// 结束时间
	endTime: string | number = 0
	// 时间轴显示时间数组
	timeList: string[] = []
}

// 日期字符串转成时间戳
export function dateStrChangeTimeTamp(dateStr: string) {
  dateStr = dateStr.substring(0, 19)
  dateStr = dateStr.replace(/\-/g, '/')
  const timeTamp = new Date(dateStr).getTime()
  return timeTamp
}
// 精准到毫秒
export function getNowTime(val: string | number) {
  const dval = typeof val === 'string' ? val.replace(/\-/g, '/') : val

  const date = new Date(dval)
  const hour = (date.getHours() - 8) < 10 ? '0' + (date.getHours() - 8) : date.getHours() - 8
  const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  const milliSeconds = date.getMilliseconds() // 毫秒
  const currentTime = hour + ':' + minute + ':' + second + '.' + milliSeconds
  return currentTime
}

// 获取5倍时间刻度
export function getNowTimeFive(val: number): string {
  const num = val * 5
  return num.toString()
}

export function timeFormat(s: number) {
  const hours = Math.floor(s / 3600)
  const minutes = Math.floor(s / 60) % 60
  const seconds = s % 60

  return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
}

// 判断网络类型
export function judgeNetWork() {
  const commonCell = ['cellular', '2g', '3g', '4g', '5g', '3G/2G']
  const unknown = { type: 'unknown' }
  const navigator = window.navigator
  console.log('navigator:')
  console.log(navigator)
  const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  let connection
  if (isiOS) {
    // @ ts-ignore
    connection = connection = navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection']
    console.log('isIOS:')
    console.log(connection)
  }
  else {
    connection = navigator['connection'] || navigator['webkitConnection'] || navigator['mozConnection']
  }
  console.log(connection)

  const type = connection ? connection.type || connection.effectiveType : null
  let result
  console.log('拿到网络状态----', type)
  if (type && typeof type === 'string') {
    switch (type) {
      // possible type values
      case 'bluetooth':
      case 'cellular':
        result = 'cellular'
        break
      case 'none':
        result = 'none'
        break
      case 'ethernet':
      case 'wifi':
      case 'wimax':
        result = 'wifi'
        break
      case 'other':
      case 'unknown':
        result = 'unknown'
        break
      // possible effectiveType values
      case 'slow-2g':
      case '2g':
      case '3g':
        result = 'cellular'
        break
      case '4g':
        result = 'wifi'
        break
      default:
        break
    }
  }
  return result
}